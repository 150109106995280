<template>
    <div class="form-group">
        <label :for="element.name">{{ element.label }}:</label>
        <input v-model="$parent.form[element.name]" type="text" class="form-control" :id="element.name"
               :placeholder="`Enter ${element.label}`">
    </div>
</template>

<script>
export default {
    name: "Text",
    props: ['element']
}
</script>

<style scoped>

</style>
