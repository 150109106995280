<template>
    <div class="container-fluid mt-3 mb-3">
        <CRow>
            <CCol sm="4">
                <h4 class="text-uppercase">Settings</h4>
            </CCol>
        </CRow>
        <div v-if="serverErrors.length">
            <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
                <span v-for="err in serverErrors" :key="err">{{ err }}</span>
            </CAlert>
        </div>
        <div class="animated fadeIn">
            <form method="post" @submit.prevent="storeSetting()" class="form-horizontal" role="form">
                <div class="row">
                    <div v-for="setting_field in setting_fields" class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <i :class="setting_field.icon"></i> {{ setting_field.title }}
                            </div>
                            <div class="card-body">
                                <component v-for="element in setting_field.elements" :is="`${element.type}-field`"
                                           :element="element"/>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary">Save Changes</button>
            </form>
        </div>
    </div>
</template>

<script>
import TextField from './fields/Text.vue'
import TextareaField from './fields/Textarea.vue'

export default {
    name: "SiteSettings",
    components: {
        TextField, TextareaField
    },
    data: () => {
        return {
            breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
                text: "System Settings",
                path: "",
                isActive: true
            }],
            serverErrors: [],
            showDismissibleAlert: false,
            isLoading: false,
            setting_fields: {},
            form: {}
        };
    },
    created() {
        this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
    },
    methods: {
        storeSetting() {
            this.isLoading = true;
            try {
                this.axios
                    .post(`/admin/settings`, this.form)
                    .then((res) => {
                        this.$toastr.s('success', res.data.message, "Settings Updated");
                        this.isLoading = false;
                        this.showDismissibleAlert = false;
                        this.serverErrors = [];
                    })
                    .catch((err) => {
                        this.showDismissibleAlert = true;
                        this.serverErrors = [];
                        this.isLoading = false;
                        if (err.response.data.message) {
                            this.serverErrors.push(err.response.data.message);
                        }

                        let errors = err.response.data.errors;
                        for (let field of Object.keys(errors)) {
                            this.$toastr.e("error!! " + errors[field][0] + " Error!");
                        }
                        this.showToast = true;
                    });
            } catch (err) {
                console.log(err);
                this.isLoading = false;
            }
        },
        getSettings() {
            this.isLoading = true;
            try {
                this.axios
                    .get(`/admin/settings`)
                    .then((res) => {
                        this.form = res.data.setting_values;
                        this.setting_fields = res.data.setting_fields;
                        this.isLoading = false;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.isLoading = false;
                    });
            } catch (err) {
                console.log(err);
                this.isLoading = false;
            }
        },
    },
    mounted() {
        this.getSettings();
    },
};
</script>
